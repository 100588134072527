<ng-container *ngIf="{ activityState: activityState$ | async } as vm">
  <ng-container *ngIf="isDefaultView">
    <app-course-header [courseState]="courseState" (clickEvent)="actionEvent($event)"></app-course-header>
  </ng-container>
  <div class="course-intended p-4">
    <app-course-heading>
      <ng-container actions>
        <ng-container *ngIf="isDefaultView">
          <button class="btn-outline btn-lg">
            <svg-icon src="@assets/images/icons/play-circle.svg" class="btn-icon"></svg-icon>
            <span>Student Preview</span>
          </button>
          <button class="btn-black btn-lg">
            <svg-icon src="@assets/images/icons/play-circle.svg" class="btn-icon"></svg-icon>
            <span>Instructor Preview</span>
          </button>
        </ng-container>
      </ng-container>
    </app-course-heading>
    <div>
      <div>
        <div class="card mb-4 block rounded-[10px] border border-neutral-100 px-6 py-9">
          <div class="mb-3">
            <div class="h5 mb-3 flex items-center gap-3 relative">
              Course Curriculum
              <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                tooltip="Course Curriculum"></svg-icon>
            </div>
            <div class="text-sm">
              The course curriculum is a plan of what students will learn in a specific course or program. It lists the
              topics and goals, and shows how the course is organized.
            </div>
          </div>
          <div class="curriculum-list h-full">
            <ng-container *ngIf="sectionsData.length; else emptySection">
              <div class="sections flex flex-col gap-3">
                <div class="section-item bg-neutral-50 pb-px" *ngFor="let section of sectionsData; let i = index"
                  [attr.sectionId]="section.id">
                  <ng-container
                    *ngTemplateOutlet="sectionHeader; context: { i: i, section: section, active: sectionCollapse[i] }">
                  </ng-container>
                  <ng-container
                    *ngTemplateOutlet="sectionContent; context: { i: i, section: section, active: sectionCollapse[i] }">
                  </ng-container>
                </div>
                <!-- <div class="section-item bg-neutral-50 pb-px">
                <ng-container *ngTemplateOutlet="sectionHeader; context: { active: true }"></ng-container>
                <ng-container *ngTemplateOutlet="sectionContent; context: { active: true }"></ng-container>
              </div> -->
              </div>
              <div class="mt-4">
                <button class="btn btn-blue" (click)="sectionModal.open()">
                  <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
                  <span>Add Section</span>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #sectionHeader let-i="i" let-section="section" let-isActive="active">
    <div class="header flex items-center justify-between rounded-[5px] px-4 py-2" [class.bg-neutral-100]="isActive">
      <div class="left flex items-center gap-3 relative">
        <div class="drag-option">
          <svg-icon src="@assets/images/icons/menu-alt-solid.svg" class="block h-4 w-4"></svg-icon>
        </div>
        <div class="section-number text-sm font-bold">Section {{ i + 1 }}:</div>
        <div class="status-tips">
          <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
            tooltip="Section {{ i + 1 }}"></svg-icon>
        </div>
        <div class="section-name flex cursor-pointer items-center gap-3" [class.hidden]="sectionEdit[i]">
          <div class="text-sm">{{ section.title }}</div>
          <div class="tag-blue tag-sm font-normal" *ngIf="section?.feedback?.updated">Recenty Updated</div>
        </div>
        <div class="section-name" [class.hidden]="!sectionEdit[i]">
          <div class="flex items-center gap-3">
            <div class="text-sm font-semibold">
              <input #titleInput placeholder="Section Name" class="input-field input-sm w-56" [value]="section.title" />
            </div>
            <div class="edit-option cursor-pointer"
              (click)="sectionEdit[i] = !sectionEdit[i]; titleInput.value = section.title ?? ''">
              <button class="btn-neutral btn-sm">Cancel</button>
            </div>
            <div class="close-option cursor-pointer"
              (click)="sectionEdit[i] = !sectionEdit[i]; editSection(section.id, titleInput.value)">
              <button class="btn-teal btn-sm">Save</button>
            </div>
          </div>
          <div class="mt-2 flex items-center gap-3" *ngIf="section?.feedback?.updated">
            <div class="tag-blue tag-sm font-normal">Recenty Updated</div>
            <div class="text-xs text-neutral-500" *ngIf="section.feedback.updated">
              Updated by
              <span class="font-semibold">{{ section.feedback.updated ? section.feedback.updated.by : '' }}</span> at
              <span class="font-semibold">{{
                section.feedback.updated ? (section.feedback.updated.date | formatDate : 'lll') : ''
                }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right flex items-center gap-3">
        <div class="section-action">
          <ng-container *ngIf="{ annotationCount: getAnnotationCountSection(section) | async } as fvm">
            <button *ngIf="!fvm.annotationCount" class="btn-soft btn-sm px-[7px]"
              (click)="onFeedbackOpenSection(section)">
              <svg-icon src="@assets/images/icons/annotation.svg" class="btn-icon"></svg-icon>
            </button>
            <button *ngIf="(fvm.annotationCount ?? 0) > 0" class="btn-teal-soft btn-sm gap-[6px] px-[7px] py-[4px]"
              (click)="onFeedbackOpenSection(section)">
              <svg-icon src="@assets/images/icons/history.svg" class="btn-icon block h-4 w-4"></svg-icon>
              <span>{{ fvm.annotationCount ?? 0 }}</span>
            </button>
          </ng-container>
          <button class="btn-amber-soft btn-sm px-[7px]" [class.hidden]="sectionEdit[i]"
            (click)="sectionEdit[i] = !sectionEdit[i]; editSection(section.id, titleInput.value)">
            <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
          </button>
          <button class="btn btn-outline-red btn-sm p-[7px]" (click)="deleteSectionModal.open()">
            <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon"></svg-icon>
          </button>
          <button class="btn-transparent btn-sm px-[7px] text-black" (click)="sectionCollapse[i] = !sectionCollapse[i]">
            <svg-icon src="@assets/images/icons/chevron-down.svg" class="btn-icon"
              [class.rotate-180]="sectionCollapse[i]"></svg-icon>
          </button>
        </div>

        <app-common-modal-confirm #deleteSectionModal title="Delete Section"
          message="Are you sure you want to delete this section?" confirmText="Delete Section"
          (confirm)="deleteSection(section.id)"></app-common-modal-confirm>
      </div>
    </div>
  </ng-template>
  <ng-template #sectionContent let-i="i" let-section="section" let-isActive="active">
    <ng-container *ngIf="isActive">
      <div class="content p-5">
        <div class="subsection-items">
          <ng-container *ngIf="sectionData[section.id]">
            <ng-container *ngTemplateOutlet="activityItem; context: { section }"></ng-container>
          </ng-container>
          <ng-container *ngIf="!sectionData[section.id]">
            <ng-container *ngTemplateOutlet="emptyActivity; context: { data: section }"></ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #activityItem let-section="section">
    <div *ngFor="let subsection of sectionData[section.id]; let actI = index" [ngClass]="'type-' + subsection.type"
      [attr.subsectionId]="subsection.id">
      <!-- Lecture article or video -->
      <ng-container *ngIf="subsection.type === 'lecture'">
        <ng-container *ngTemplateOutlet="
            subSectionItem;
            context: {
              type: 'lecture',
              subtype: subsection.lecture?.type,
              number: 1,
              section,
              subsection,
              data: subsection.lecture
            }
          "></ng-container>
      </ng-container>

      <!-- Quiz -->
      <ng-container *ngIf="subsection.type === 'quiz'">
        <ng-container *ngTemplateOutlet="
            subSectionItem;
            context: {
              type: 'quiz',
              number: 1,
              section,
              subsection,
              data: subsection.quiz
            }
          "></ng-container>
      </ng-container>

      <!-- Assignment -->
      <ng-container *ngIf="subsection.type === 'assignment'">
        <ng-container *ngTemplateOutlet="
            subSectionItem;
            context: {
              type: 'assignment',
              number: 1,
              section,
              subsection,
              data: subsection.assignment
            }
          "></ng-container>
      </ng-container>
    </div>
    <div class="mt-4">
      <button class="btn btn-blue"
        (click)="vm.activityState = 'type_lecture'; createActivity(section.id, 'type_lecture')">
        <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
        <span>Lecture</span>
      </button>
      <button class="btn btn-blue"
        (click)="vm.activityState = 'type_assignment'; createActivity(section.id, 'type_assignment')">
        <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
        <span>Assignment</span>
      </button>
      <button class="btn btn-blue" (click)="vm.activityState = 'type_quiz'; createActivity(section.id, 'type_quiz')">
        <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
        <span>Quiz</span>
      </button>
    </div>
  </ng-template>

  <ng-template #subSectionItem let-type="type" let-number="number" let-subtype="subtype" let-section="section"
    let-subsection="subsection" let-data="data">
    <div class="subsection-item mb-4 flex items-center gap-4">
      <div>
        <div class="drag-option text-neutral-700 hover:text-black">
          <svg-icon src="@assets/images/icons/menu-solid.svg" class="block h-5 w-5"></svg-icon>
        </div>
      </div>
      <div class="grow">
        <div class="preview gap-4 rounded-[5px] bg-white px-4 py-1 shadow">
          <div class="header flex items-center justify-between py-2 relative">
            <div class="left flex items-center gap-1.5">
              <div class="item-numberfont-semibold w-[120px] truncate capitalize">{{ type }} :</div>
              <div class="section-name flex cursor-pointer items-center gap-3">
                <div>{{ data.title }}</div>
              </div>
              <div class="status-tips">
                <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
                  tooltip="{{ type }} title"></svg-icon>
              </div>
              <ng-container *ngIf="data?.feedback?.updated">
                <div class="tag-blue tag-sm font-normal">Recenty Updated</div>
              </ng-container>
            </div>
            <div class="right flex items-center gap-3">
              <div class="section-action">
                <ng-container *ngIf="{ annotationCount: getAnnotationCountActivity(subsection) | async } as fvm">
                  <button *ngIf="!fvm.annotationCount" class="btn-soft btn-sm px-[7px]"
                    (click)="onFeedbackOpenActivity(subsection)">
                    <svg-icon src="@assets/images/icons/annotation.svg" class="btn-icon"></svg-icon>
                  </button>
                  <button class="btn-teal-soft btn-sm gap-[6px] px-[7px] py-[4px]"
                    *ngIf="(fvm.annotationCount ?? 0) > 0" (click)="onFeedbackOpenActivity(subsection)">
                    <svg-icon src="@assets/images/icons/history.svg" class="btn-icon block h-4 w-4"></svg-icon>
                    <span>{{ fvm.annotationCount ?? 0 }}</span>
                  </button>
                </ng-container>

                <!-- Edit & Delete Buttons -->
                <ng-container>
                  <button class="btn-amber-soft btn-sm px-[7px]" (click)="
                      editActivity(
                        section.id,
                        subsection.id,
                        data.id,
                        (type === 'quiz' ? 'type_quiz' : '') ||
                          (type === 'assignment' ? 'type_assignment' : '') ||
                          (type === 'lecture' ? 'type_lecture' : 'type_lecture')
                      )
                    ">
                    <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
                  </button>
                  <button class="btn btn-outline-red btn-sm p-[7px]" (click)="deleteSubsectionModal.open()">
                    <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon"></svg-icon>
                  </button>
                </ng-container>
                <button class="btn-transparent btn-sm px-[7px] text-black"
                  (click)="subSectionCollapse[subsection.id] = !subSectionCollapse[subsection.id]">
                  <svg-icon src="@assets/images/icons/chevron-down.svg" class="btn-icon h-5 w-5"
                    [class.rotate-180]="subSectionCollapse[subsection.id]"></svg-icon>
                </button>

                <app-common-modal-confirm #deleteSubsectionModal title="Delete {{ type | titlecase }}"
                  message="Are you sure you want to delete this {{ type }}?" confirmText="Delete {{ type | titlecase }}"
                  (confirm)="deleteSubsection(subsection.id)"></app-common-modal-confirm>
              </div>
            </div>
          </div>
          <div class="type flex items-center gap-1">
            <div class="mb-2 flex w-[120px] items-center gap-2 text-xs text-neutral-500">
              <svg-icon src="@assets/images/icons/book-open.svg" class="inline-block h-[18px] w-[18px]"
                *ngIf="subtype === 'Article'"></svg-icon>
              <svg-icon src="@assets/images/icons/play-circle-solid.svg" class="inline-block h-[18px] w-[18px]"
                *ngIf="subtype === 'Video'"></svg-icon>
              <svg-icon src="@assets/images/icons/puzzle-piece-solid.svg" class="inline-block h-[18px] w-[18px]"
                *ngIf="subtype === 'quiz' || type === 'quiz'"></svg-icon>
              <svg-icon src="@assets/images/icons/clipboard-solid.svg" class="inline-block h-[18px] w-[18px]"
                *ngIf="subtype === 'assignment' || type === 'assignment'"></svg-icon>
              <span class="capitalize">{{ subtype ? subtype : type }}</span>
            </div>
            <div class="mb-2 flex grow items-center gap-3 border-b border-neutral-100"
              [class.hidden]="!subSectionCollapse[subsection.id]">
              <div class="mb-2 text-xs text-neutral-500" *ngIf="data?.feedback?.updated">
                Updated by
                <span class="font-semibold">{{ data?.feedback.updated ? data?.feedback.updated.by : '' }}</span> at
                <span class="font-semibold">{{
                  data?.feedback.updated ? (data?.feedback.updated.date | formatDate : 'lll') : ''
                  }}</span>
              </div>
            </div>
            <!-- <div class="line h-px grow bg-neutral-100" [class.hidden]="!subSectionCollapse[subsection.id]"></div> -->
          </div>
          <div class="preview-content mb-4" [class.hidden]="!subSectionCollapse[subsection.id]">
            <div class="pl-[120px]">
              <ng-container *ngIf="subtype === 'Article'">
                <ng-container *ngTemplateOutlet="previewArticle"></ng-container>
              </ng-container>
              <ng-container *ngIf="subtype === 'Video'">
                <ng-container *ngTemplateOutlet="previewVideo; context: { previewUrl: data.video_url }"></ng-container>
              </ng-container>
              <ng-container *ngIf="subtype === 'quiz' || type === 'quiz'">
                <ng-container *ngTemplateOutlet="itemsQuiz; context: { section, subsection, quiz: data }">
                </ng-container>
              </ng-container>
              <ng-container *ngIf="subtype === 'assignment' || type === 'assignment'">
                <ng-container *ngTemplateOutlet="previewAssignment; context: { assignment: data }"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- Preview of subsection item -->
  <ng-template #previewArticle>
    <div class="text-sm text-neutral-700">
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make
        a type specimen book.
      </p>
    </div>
  </ng-template>
  <ng-template #previewVideo let-previewUrl="previewUrl">
    <div class="flex items-center gap-4">
      <div class="h-32 overflow-hidden rounded-lg">
        <video class="h-full w-auto" [src]="previewUrl" controls></video>
      </div>
      <div>
        <div class="mb-2 font-semibold">Lesson 1.mp4</div>
        <div class="text-sm text-neutral-600">Duration: 20 Min</div>
      </div>
    </div>
  </ng-template>
  <ng-template #previewQuiz let-question="question">
    <div class="flex gap-2 font-bold relative">
      Answers
      <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
        tooltip="Quiz answer"></svg-icon>
    </div>
    <div class="answers-list">
      <ng-container *ngFor="let answer of question.answers; let i = index">
        <div class="input-radio border-b border-neutral-200 py-[15px]">
          <input id="answer-a" [disabled]="true" type="radio" value="" [checked]="answer.is_correct" />
          <label for="answer-a">{{ i | indexToAlphabet }}. {{ answer.answer }}</label>
        </div>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #previewAssignment let-assignment="assignment">
    <div class="flex gap-2 font-bold relative">
      Questions
      <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
        tooltip="Assignment question"></svg-icon>
    </div>
    <div class="questions-list text-sm">
      <div class="flex items-center justify-between border-b border-neutral-100 py-3"
        *ngFor="let question of assignment.questions; let i = index">
        <div class="left flex flex-col">
          <div>
            <span class="font-semibold">Question {{ i + 1 }}:</span> {{ question.question }}
          </div>
          <div>
            <span class="font-semibold">Answer {{ i + 1 }}:</span> {{ question.expected }}
          </div>
        </div>
        <div class="right flex items-center gap-3">
          <div class="section-action">
            <ng-container *ngIf="{ annotationCount: getAnnotationCountAssignmentQuestion(question) | async } as fvm">
              <button *ngIf="!fvm.annotationCount" class="btn-soft btn-sm px-[7px]"
                (click)="onFeedbackOpenAssignmentQuestion(question)">
                <svg-icon src="@assets/images/icons/annotation.svg" class="btn-icon"></svg-icon>
              </button>
              <button *ngIf="(fvm.annotationCount ?? 0) > 0" class="btn-teal-soft btn-sm gap-[6px] px-[7px] py-[4px]"
                (click)="onFeedbackOpenAssignmentQuestion(question)">
                <svg-icon src="@assets/images/icons/history.svg" class="btn-icon block h-4 w-4"></svg-icon>
                <span>{{ fvm.annotationCount ?? 0 }}</span>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="mt-4">
      <button class="btn btn-blue">
        <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
        Add Question
      </button>
    </div> -->
  </ng-template>

  <!-- Quiz and Assignment Sub Items [Section > Sub Section > Quiz > Item] -->

  <!-- Quiz Items -->
  <ng-template #itemsQuiz let-section="section" let-subsection="subsection" let-quiz="quiz">
    <!-- (click)="subSectionCollapse[subsection.id] = !subSectionCollapse[subsection.id]" -->
    <div class="quiz-items">
      <ng-container *ngFor="let question of quiz.questions; let i = index">
        <ng-container *ngTemplateOutlet="
            itemQuiz;
            context: {
              section,
              subsection,
              quiz,
              question,
              active: quizCollapse[question.id] ? true : false,
              number: i
            }
          "></ng-container>
      </ng-container>
    </div>
    <!-- <button class="btn btn-blue" (click)="editActivity(section.id, subsection.id, quiz.id, 'type_quiz')">
      <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
      Add Question
    </button> -->
  </ng-template>
  <!-- Each Quiz Item -->
  <ng-template #itemQuiz let-section="section" let-subsection="subsection" let-quiz="quiz" let-question="question"
    let-isActive="active" let-number="number">
    <div class="quiz-item mb-4 rounded-[5px] bg-neutral-50">
      <div class="header flex items-center justify-between p-2 text-sm relative">
        <div class="left flex items-center gap-3">
          <div>
            <svg-icon src="@assets/images/icons/menu-alt-solid.svg" class="block h-4 w-4"></svg-icon>
          </div>
          <div class="item-number truncate font-semibold capitalize">Question {{ number + 1 }}:</div>
          <div class="status-tips">
            <svg-icon src="@assets/images/icons/question-mark-solid.svg" class="block h-5 w-5"
              tooltip="Quiz question"></svg-icon>
          </div>
          <div class="section-name cursor-pointer flex-col items-center gap-3">
            <div>{{ question.question }}</div>
            <div class="text-neutral-500">Answer : A</div>
          </div>
        </div>
        <div class="right flex items-center gap-3">
          <div class="section-action">
            <ng-container *ngIf="{ annotationCount: getAnnotationCountQuizQuestion(question) | async } as fvm">
              <button *ngIf="!fvm.annotationCount" class="btn-soft btn-sm px-[7px]"
                (click)="onFeedbackOpenQuizQuestion(question)">
                <svg-icon src="@assets/images/icons/annotation.svg" class="btn-icon"></svg-icon>
              </button>
              <button *ngIf="(fvm.annotationCount ?? 0) > 0" class="btn-teal-soft btn-sm gap-[6px] px-[7px] py-[4px]"
                (click)="onFeedbackOpenQuizQuestion(question)">
                <svg-icon src="@assets/images/icons/history.svg" class="btn-icon block h-4 w-4"></svg-icon>
                <span>{{ fvm.annotationCount ?? 0 }}</span>
              </button>
            </ng-container>
            <!-- <button
              class="btn-amber-soft btn-sm px-[7px]"
              (click)="editActivity(section.id, subsection.id, quiz.id, 'type_quiz')"
            >
              <svg-icon src="@assets/images/icons/pencil.svg" class="btn-icon"></svg-icon>
            </button>
            <button class="btn btn-outline-red btn-sm p-[7px]">
              <svg-icon src="@assets/images/icons/trash.svg" class="btn-icon"></svg-icon>
            </button> -->
            <button class="btn-transparent btn-sm px-[7px] text-black"
              (click)="quizCollapse[question.id] = !quizCollapse[question.id]">
              <svg-icon src="@assets/images/icons/chevron-down.svg" class="btn-icon h-5 w-5"
                [class.rotate-180]="quizCollapse[question.id]"></svg-icon>
            </button>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isActive">
        <div class="preview pb-4 pl-[150px] pr-9">
          <div class="rounded-[5px] bg-white p-4">
            <ng-container *ngTemplateOutlet="previewQuiz; context: { question }"></ng-container>
            <!-- <button class="btn btn-teal">Add Answer</button> -->
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #itemsAssignment>
    <div>Assignment List Here</div>
  </ng-template>

  <!-- Empty State -->
  <ng-template #emptySection>
    <div class="flex min-h-[70vh] w-full">
      <div class="m-auto flex flex-col gap-6 text-center">
        <div class="mx-auto">
          <svg-icon src="@assets/images/no-item-placeholder.svg" class="block w-40"></svg-icon>
        </div>
        <div>
          <h4>No items added</h4>
          <div>Fortunately it's very easy to add new ones.</div>
        </div>
        <div>
          <div class="mt-4">
            <button class="btn btn-teal" (click)="sectionModal.open()">
              <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon> Add Section
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #emptyActivity let-section="data">
    <div class="flex min-h-[500px] w-full">
      <div class="m-auto flex flex-col gap-6 text-center">
        <div class="mx-auto">
          <svg-icon src="@assets/images/no-item-placeholder.svg" class="block w-40"></svg-icon>
        </div>
        <div>
          <h4>No items added</h4>
          <div>Fortunately it's very easy to add new ones.</div>
        </div>
        <div>
          <!-- (click)="createActivity(section.id)" -->
          <div class="mt-4">
            <button class="btn btn-blue"
              (click)="vm.activityState = 'type_lecture'; createActivity(section.id, 'type_lecture')">
              <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
              <span>Lecture</span>
            </button>
            <button class="btn btn-blue"
              (click)="vm.activityState = 'type_assignment'; createActivity(section.id, 'type_assignment')">
              <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
              <span>Assignment</span>
            </button>
            <button class="btn btn-blue"
              (click)="vm.activityState = 'type_quiz'; createActivity(section.id, 'type_quiz')">
              <svg-icon src="@assets/images/icons/plus.svg" class="btn-icon"></svg-icon>
              <span>Quiz</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <!-- All Modals -->
  <common-modal #sectionModal title="Add Section" customClass="sm:w-full sm:max-w-xl">
    <ng-container content>
      <app-course-section #sectionComponent (createEvent)="sectionHandler('created', $event)"
        (cancelEvent)="sectionHandler('cancel', $event)"></app-course-section>
    </ng-container>
  </common-modal>

  <common-modal #activityModal [title]="false" [customClass]="
      vm.activityState && activitySize[vm.activityState] ? activitySize[vm.activityState] : 'sm:w-full sm:max-w-3xl'
    " (closeEvent)="closeActivity()">
    <ng-container content>
      <app-activity-lecture (nextEvent)="onActivityModalNext('lecture_' + $event)"
        (createEvent)="lectureHandler('created', $event)" (saveEvent)="lectureHandler('saved', $event)"
        (cancelEvent)="lectureHandler('cancel', $event)" *ngIf="
          vm.activityState === 'type_lecture' ||
          vm.activityState === 'lecture_video' ||
          vm.activityState === 'lecture_article'
        "></app-activity-lecture>
      <app-activity-quiz (quizCreateEvent)="quizHandler('quiz_created', $event)"
        (quizSaveEvent)="quizHandler('quiz_save', $event)" (qACreateEvent)="quizHandler('qa_created', $event)"
        (qASaveEvent)="quizHandler('qa_saved', $event)" (qADeleteEvent)="quizHandler('qa_deleted', $event)"
        (cancelEvent)="quizHandler('cancel', $event)" *ngIf="vm.activityState === 'type_quiz'"></app-activity-quiz>
      <app-activity-assignment (createEvent)="assignmentHandler('created', $event)"
        (saveEvent)="assignmentHandler('save', $event)" (cancelEvent)="assignmentHandler('cancel', $event)"
        *ngIf="vm.activityState === 'type_assignment'"></app-activity-assignment>
    </ng-container>
  </common-modal>

  <common-modal #feedbackModal title="Feedback for Instructor" customClass="sm:w-full sm:max-w-3xl"
    (closeEvent)="onFeedbackCancel()">
    <ng-container content *ngIf="feedbackModal.isOpened">
      <app-common-feedback (submitEvent)="onFeedbackSubmit($event)" (cancel)="onFeedbackCancel()"></app-common-feedback>
    </ng-container>
  </common-modal>
</ng-container>
