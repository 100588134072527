<div class="course-overview p-4 pt-0">
   <div class="page-heading mb-4">
      <h3>Orders</h3>
   </div>
   <div class="page-heading mb-4 flex items-center justify-between">
      <div class="actions flex gap-4">
         <!-- Filter -->
         <div class="relative">
            <button (click)="dropdown(1)" class="btn-neutral">
               <svg-icon src="@assets/images/icons/filter.svg" class="btn-icon"></svg-icon>
               <span>Filter</span>
            </button>
            <div [ngClass]="isDropdown[1] ? '':'hidden'"
               class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto left-0">
               <div class="flex flex-col">
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Status
                     </div>
                     <app-filter-option #filterStatus key="status" [options]="filterOptions.status"
                        [defaultValue]="filterValues.status" (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Sale price
                     </div>
                     <app-filter-option #filterSalePrice [formType]="'input'" [keyObj]="salePriceKey"
                        (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex flex-col">
                     <div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5">
                        Transactions
                     </div>
                     <app-filter-option #filterPlatformEarning [formType]="'input'" [keyObj]="platformEarningKey"
                        (filterEvent)="filterHander($event)">
                     </app-filter-option>
                  </div>
                  <div class="flex gap-2.5 border-t-neutral-100 border-t pt-4">
                     <button
                        (click)="clearFilter([filterSalePrice, filterStatus, filterSalePrice, filterPlatformEarning])"
                        type="button" class="btn btn-outline-dark border-neutral-600">
                        <span>Reset</span>
                     </button>
                     <button
                        (click)="submitFilter([filterSalePrice, filterStatus, filterSalePrice, filterPlatformEarning])"
                        class="btn btn-dark bg-neutral-600 text-white">
                        <span>Filter</span>
                     </button>
                  </div>
               </div>
            </div>
         </div>
         <div class="input-combine flex">
            <div class="select2">
               <div class="relative">
                  <a (click)="dropdown(2)" type="button" aria-haspopup="listbox" aria-expanded="true"
                     aria-labelledby="listbox-label" class="btn-neutral rounded-r-none">
                     <div class="selected-item truncate">{{selectedItems.title}}</div>
                     <svg-icon src="@assets/images/icons/chevron-down-solid.svg" class="btn-icon"></svg-icon>
                  </a>
                  <ul [ngClass]="isDropdown[2]?'':'hidden'" tabindex="-1" role="listbox" aria-labelledby="listbox-label"
                     aria-activedescendant="listbox-option-3" class="select-items absolute right-0 min-w-fit"
                     ng-reflect-ng-class="hidden">
                     <li *ngFor="let type of searchType" (click)="dropdown(2); selectItems(type)" id="listbox-option-0"
                        role="option" class="item">{{type.title}}</li>
                  </ul>
               </div>
            </div>
            <div class="input-group">
               <div class="group rounded-l-none">
                  <svg-icon src="@assets/images/icons/search2.svg" class="input-icon"></svg-icon>
                  <input (keyup.enter)="search($event)" placeholder="Enter your full name" />
               </div>
            </div>
         </div>
      </div>
      <button class="btn px-6 py-3 bg-blue-50 rounded-[4px] gap-2 border-0">
         <svg-icon src="@assets/images/icons/export-icon.svg" class="text-blue-600"></svg-icon>
         <span class="text-blue-600 font-semibold text-sm">Exprot CSV</span>
      </button>
   </div>

   <div class="courses-list">
      <div class="card rounded-lg border border-neutral-100">
         <div class="card-subheader flex items-center justify-between px-4 py-5">
            <div class="flex items-center gap-1">
               <h6>Orders</h6>
               <div class="tag tag-sm">{{totalOrder}} Order</div>
            </div>
            <div class="actions relative">
               <button (click)="dropdown(3)" class="btn-neutral px-2">
                  <svg-icon src="@assets/images/icons/dots-vertical.svg" class="btn-icon"></svg-icon>
               </button>
               <div [ngClass]="isDropdown[3] ? '':'hidden'"
                  class="bg-white shadow-lg rounded px-2.5 py-5 w-[226] absolute z-10 border border-neutral-100 max-h-80 overflow-y-auto right-0">
                  <div *ngFor="let col of columns; let i = index" class="input-check w-48 my-2">
                     <input [checked]="col.visible" (change)="toggleColumn(col)" type="checkbox"
                        id="default-checkbox-{{i}}">
                     <label for="default-checkbox-{{i}}">{{col.name}}</label>
                  </div>
               </div>
            </div>
         </div>
         <div class="card-body">
            <div class="courses-list relative">
               <ng-container *ngIf="isLoading">
                  <ng-container *ngTemplateOutlet="loader"></ng-container>
               </ng-container>
               <ng-container *ngIf="orderLists.length === 0 && !isLoading; else isTransactions">
                  <span class="text-center text-2xl my-11 block">No Order Founded!</span>
               </ng-container>
               <ng-template #isTransactions>
                  <ng-container *ngTemplateOutlet="listTransactions; context: { items: orderLists }">
                  </ng-container>
               </ng-template>
               <!-- Pagination -->
               <div class="z-30 relative">
                  <app-common-pagination (paginatorData)="paginat($event)" [type]="'dynamic'"></app-common-pagination>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>

<!-- Table -->
<ng-template #listTransactions let-items="items">
   <div class="datagrid-table relative overflow-x-auto min-h-[936px]">
      <table class="w-full text-left">
         <thead class="heading">
            <tr>
               <ng-container *ngFor="let column of columns">
                  <th *ngIf="column.visible" scope="col" class="">
                     <div class="col-name">{{column.name}}</div>
                  </th>
               </ng-container>
            </tr>
         </thead>
         <tbody>
            <tr class="hover:cursor-pointer" *ngFor="let transaction of items"
               [routerLink]="'/ecommerce/order/'+transaction.id">
               <td *ngIf="columns[0].visible">
                  <span class="course-id">
                     <span class="text-blue-600 underline cursor-pointer">
                        #{{ transaction.id }}
                     </span>
                  </span>
               </td>
               <td *ngIf="columns[1].visible">
                  <div class="flex min-h-[69px] max-w-[170px] items-center">
                     <span class="course-name line-clamp-3 text-blue-600 underline cursor-pointer">
                        {{ transaction.order_id }}
                     </span>
                  </div>
               </td>
               <td *ngIf="columns[2].visible" class="max-w-[170px]">
                  <span class="whitespace-nowrap text-blue-600 underline cursor-pointer">
                     {{ transaction.user.fullname }}
                  </span>
               </td>
               <td *ngIf="columns[3].visible">
                  {{transaction.date_created | date}}
               </td>
               <td *ngIf="columns[4].visible">
                  {{transaction.products}}
               </td>
               <td *ngIf="columns[5].visible">
                  {{transaction.currency_symbol}}{{transaction.gross_amount}}
               </td>
               <td *ngIf="columns[6].visible">
                  <div *ngIf="transaction.status === 'created'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Created
                  </div>
                  <div *ngIf="transaction.status === 'saved'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Saved
                  </div>
                  <div *ngIf="transaction.status === 'approved'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Approved
                  </div>
                  <div *ngIf="transaction.status === 'voided'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Voided
                  </div>
                  <div *ngIf="transaction.status === 'failed'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Failed
                  </div>
                  <div *ngIf="transaction.status === 'completed'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Completed
                  </div>
                  <div *ngIf="transaction.status === 'payer_action_required'" class="tag tag-{{ getStatusTagColor(transaction.status) }} tag-sm" i18n>
                     Payer Action Required
                  </div>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</ng-template>

<!-- loader -->
<ng-template #loader>
   <div class="flex items-center justify-center w-full h-full left-0 right-0 absolute bg-[rgba(255,255,255,0.5)] z-20">
      <div class="loader">
         <svg class="text-blue-500 m-auto h-8 w-8 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
               d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
         </svg>
      </div>
   </div>
</ng-template>