import { CurrencyPipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import { DrawerComponent, ModalComponent, UntilDestroy, untilDestroyed } from '@shared';
import {
  CombinationSingleInputItem,
  CombinationSingleInputValue,
} from '@shared/components/combination-single-input/combination-single-input.component';
import { BreadcrumbService } from '@shared/services/breadcrumb/breadcrumb.service';
import { PayoutService } from '@shared/services/payout/payout.service';
import { PaginationChange } from 'projects/thkee-common/src/lib/components/pagination/pagination.component';
import { NestedCheckboxDirective } from 'projects/thkee-common/src/lib/directives/nested-checkbox.directive';
import { BehaviorSubject, catchError, debounceTime, of } from 'rxjs';
import {
  AdminPayoutReadyRequestQuery,
  AdminPayoutReadyRequestsSummary,
  AdminPayoutRequest,
  AdminPayoutRetryInformation,
  AdminReadyRequestDetail,
  Pagination,
  PayoutMethodName,
  PayoutRequestStatus,
  QueryFormConverter,
  QueryStringFilterService,
  ToastService,
} from 'thkee-common';

const FILTER_PAYOUT_STATUS: { value: PayoutRequestStatus; label: string }[] = [
  { value: 'paid', label: 'Paid' },
  { value: 'requested', label: 'Requested' },
  { value: 'ready', label: 'Ready' },
  { value: 'deactivated', label: 'Deactivated' },
  { value: 'failed', label: 'Failed' },
];

@UntilDestroy()
@Component({
  selector: 'app-ready-requests',
  templateUrl: './ready-requests.component.html',
  styleUrls: ['./ready-requests.component.scss'],
  providers: [...QueryStringFilterService.forComponent()],
})
export class ReadyRequestsComponent implements OnInit, AfterViewInit {
  @ViewChild('payoutCheckboxes', { read: NestedCheckboxDirective })
  private payoutCheckboxes!: NestedCheckboxDirective;

  @ViewChild('formlyFilter', { read: FormlyForm })
  formlyFilter!: FormlyForm;

  @ViewChild('retryReleaseModal', { read: ModalComponent })
  private retryReleaseModal!: ModalComponent;

  @ViewChild('releaseManyModal', { read: ModalComponent })
  private releaseManyModal!: ModalComponent;

  @ViewChild('markAsFailedModal', { read: ModalComponent })
  private markAsFailedModal!: ModalComponent;

  @ViewChild('releaseAllModal', { read: ModalComponent })
  private releaseAllModal!: ModalComponent;

  @ViewChild('transactionsDrawer', { read: DrawerComponent })
  private transactionsDrawer!: DrawerComponent;

  requestPagination?: Pagination<AdminPayoutRequest>;

  requestsSummary?: AdminPayoutReadyRequestsSummary;

  searchOptionMetadata: CombinationSingleInputItem[] = [
    {
      id: 'id',
      title: 'ID',
      placeholder: 'Enter ID',
    },
    {
      id: 'request_id',
      title: 'Request ID',
      placeholder: 'Enter Request ID',
    },
    {
      id: 'name',
      title: 'Instructor Name',
      placeholder: 'Enter Instructor Name',
    },
  ];

  requestingChange = false;

  markAsFailedForm = new FormGroup({});
  markAsFailedFormFields: FormlyFieldConfig[] = [
    {
      key: 'reason',
      type: 'textarea',
      props: {
        label: 'Reason for failure',
        labelClass: 'font-semibold text-black text-lg',
        placeholder: 'Enter a reason',
        required: true,
        rows: 3,
        minHeight: '110px',
        maxHeight: '100%',
      },
      validation: {
        messages: {
          required: $localize`Reason is required!`,
        },
      },
    },
  ];


  // Filter form
  filterForm = new FormGroup({});
  filterFormFields: FormlyFieldConfig[] = [
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5">Payout Type</div>`,
    },
    {
      key: 'payout_type',
      fieldGroup: [
        {
          key: 'on_demand',
          type: 'checkbox',
          className: '',
          props: {
            label: 'On-demand (16)',
            required: true,
          },
        },
        {
          key: 'monthly',
          type: 'checkbox',
          className: '',
          props: {
            label: 'Monthly (16)',
            required: true,
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mb-2.5 mt-2">Payout Status</div>`,
    },
    {
      key: 'status',
      fieldGroup: FILTER_PAYOUT_STATUS.map((data) => {
        return {
          key: data.value,
          type: 'checkbox',
          className: '',
          props: {
            label: data.label,
            required: true,
          },
        };
      }),
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Payout Amount</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'requested_amount_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'requested_amount_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            placeholder: '$ 20',
          },
        },
      ],
    },
    {
      template: `<div class="text-base text-neutral-800 font-semibold border-b border-neutral-100 pb-2.5 mt-2">Transactions</div>`,
    },
    {
      fieldGroupClassName: 'flex gap-2 items-center mb-4',
      fieldGroup: [
        {
          key: 'no_of_transactions_min',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '1',
          },
        },
        {
          template: '-',
        },
        {
          key: 'no_of_transactions_max',
          type: 'input',
          className: 'w-[78px]',
          props: {
            label: '',
            type: 'number',
            placeholder: '20',
          },
        },
      ],
    },
  ];
  isDropdownShown: Record<'course' | 'filter', boolean> = { course: false, filter: false };
  approvingRequestId?: number;

  searchValue: CombinationSingleInputValue = { key: 'id' };
  pagination?: PaginationChange;
  selectedRequests: AdminPayoutRequest[] = [];
  disableAllButton = false;
  processingRequest?: AdminPayoutRequest;
  requestDetail?: AdminReadyRequestDetail;
  openDropdownId?: number;
  summaryItems: { id?: PayoutMethodName; value: string; title: string }[] = [];
  query: AdminPayoutReadyRequestQuery = {};
  planToProcessingPayouts: AdminPayoutRequest[] | undefined;
  totalReadyRequest: number = 0;

  private triggerReloadRequests = new BehaviorSubject(true);
  private queryFormConverter = new QueryFormConverter<AdminPayoutReadyRequestQuery>(['status', 'payout_type']);
  private currencyPipe = new CurrencyPipe('en');
  retryPayoutInfor?: AdminPayoutRetryInformation;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private payoutService: PayoutService,
    private queryStringFilter: QueryStringFilterService<AdminPayoutReadyRequestQuery>,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getRequests();
    this.getRequestsSummary();

    let breadcrumb: any = [
      {
        label: 'E-Commerce',
        url: '/ecommerce/dashboard',
      },
      {
        label: 'Payouts',
        url: '/ecommerce/payout/paid-payout/all',
      },
      {
        label: 'Payout Request',
        url: '/ecommerce/payout/payouts-requests/all',
      },
    ];
    this.breadcrumbService.setBreadcrumbs(breadcrumb);
  }

  ngAfterViewInit(): void {
    this.setupQueryFilter();
  }

  handlePayoutPaid(ok: boolean) {
    if (ok) {
      this.queryStringFilter.refresh();
      this.payoutCheckboxes.uncheck();
    }
  }

  openConfirmation(modal: ModalComponent, payouts?: AdminPayoutRequest[]) {
    this.planToProcessingPayouts = payouts;
    modal.open();
  }

  openConfirmationAllReadyRequests(modal: ModalComponent) {
    this.planToProcessingPayouts = undefined;
    modal.open();
    this.payoutService.getReadyRequestsAmount().subscribe((value) => (this.totalReadyRequest = value));
  }

  handleSelectedPayoutsChange($event: AdminPayoutRequest[]) {
    this.selectedRequests = $event;
    this.disableAllButton = $event.some((p) => p.status !== 'ready');
  }

  filterByPayoutMethod(method?: PayoutMethodName) {
    if (method) {
      this.queryStringFilter.patch({ payout_method: method });
      return;
    }

    this.queryStringFilter.remove(['payout_method']);
  }

  openRequestDetail(data: AdminPayoutRequest, view: DrawerComponent) {
    this.processingRequest = data;
    this.payoutService.getReadyRequestDetails(data.id).subscribe((data) => (this.requestDetail = data));
    view.open();
  }

  openRetryReleaseModal(data: AdminPayoutRequest) {
    this.processingRequest = data;
    this.retryPayoutInfor = undefined;
    this.payoutService.getRetryPayoutDetail(data.id).subscribe(res => {
      this.retryPayoutInfor = res;
    });
    this.retryReleaseModal.open();
  }

  retryRelease() {
    if (!this.processingRequest) {
      return;
    }

    this.requestingChange = true;
    this.payoutService.retryFailedPayout(this.processingRequest.id)
      .pipe(catchError(() => of(false)))
      .subscribe(ok => {
        this.requestingChange = false;
        if (!ok) {
          this.toastService.message({
            type: 'error',
            message: 'Retry payout failed'
          });
          return;
        }

        this.queryStringFilter.refresh();
        this.toastService.message({
          type: 'message',
          message: 'Retried payout successfully'
        });
        this.retryReleaseModal.close();
      })
  }

  markAsFail() {
    if (!this.processingRequest?.id || this.markAsFailedForm.invalid) {
      return;
    }

    this.requestingChange = true;
    this.payoutService
      .markReadyRequestAsFailed(this.processingRequest?.id, this.markAsFailedForm.get('reason')?.value || '')
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.requestingChange = false;
        if (ok) {
          this.markAsFailedModal.close();
          this.queryStringFilter.refresh();
          this.toastService.message({
            message: 'Request is marked as failed',
            type: 'message',
          });
        }
      });
  }

  openMarkAsFailedModal(data: AdminPayoutRequest) {
    this.processingRequest = data;
    this.markAsFailedModal.open();
  }

  openTransactions(req: AdminPayoutRequest) {
    this.processingRequest = req;
    this.transactionsDrawer.open();
  }

  openReleaseMany(appliedAll?: boolean) {
    if (appliedAll) {
      this.releaseAllModal.open();
      this.dropdown('batchOption');
      return;
    }

    this.releaseManyModal.open();
  }

  paginate($event: PaginationChange) {
    this.selectedRequests = [];
    this.queryStringFilter.patch($event);
  }

  // Dropdown methode
  isDropdown?: 'batchOption' | 'filter' | 'search';
  dropdown(type: 'batchOption' | 'filter' | 'search') {
    this.isDropdown = this.isDropdown === type ? undefined : type;
  }

  resetFilterRequests() {
    this.filterForm.reset();
    this.dropdown('filter');
  }

  // Status methode
  getStatus(status: string = 'Paid') {
    let style = { bg: '', text: '' };
    if (status === 'Paid') {
      style.bg = 'bg-teal-50';
      style.text = 'text-teal-600';
    } else if (status === 'Failed') {
      style.bg = 'bg-rose-50';
      style.text = 'text-rose-600';
    } else {
      style.bg = 'bg-neutral-50';
      style.text = 'text-neutral-600';
    }
    return style;
  }

  toggleDropdownTranId(id: number) {
    if (this.openDropdownId === id) {
      this.openDropdownId = undefined;
      return;
    }

    this.openDropdownId = id;
  }

  releaseMany(modal: ModalComponent) {
    this.requestingChange = true;
    const reqIds = this.planToProcessingPayouts?.length ? this.planToProcessingPayouts.map((p) => p.id) : undefined;
    this.payoutService
      .releaseReadyRequests(reqIds)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.requestingChange = false;
        if (ok) {
          this.payoutCheckboxes.uncheck();
          this.queryStringFilter.refresh();
          this.toastService.message({
            message: 'Release requests successfully',
            type: 'message',
          });

          modal.close();
        }
      });
  }

  deactivateManyRequests(modal: ModalComponent) {
    this.requestingChange = true;
    this.payoutService
      .deactivateReadyRequests(this.planToProcessingPayouts ? this.planToProcessingPayouts.map((r) => r.id) : undefined)
      .pipe(catchError(() => of(false)))
      .subscribe((ok) => {
        this.requestingChange = false;
        if (ok) {
          this.payoutCheckboxes.uncheck();
          this.queryStringFilter.refresh();
          this.toastService.message({
            message: 'Deactivate requests successfully',
            type: 'message',
          });

          modal.close();
        }
      });
  }

  search(value: CombinationSingleInputValue) {
    if (value.value) {
      this.queryStringFilter.patch({ search: [value.key, value.value].join(':') });
      return;
    }

    this.queryStringFilter.remove(['search']);
  }

  /**
   * Because of formly form will be intialized (available) after onInit,
   * then need to call this function at AfterViewInit hook,
   * making sure the formly form will be available at that time
   */
  private setupQueryFilter() {
    this.filterForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.queryStringFilter.patch(this.queryFormConverter.toQuery(value as any));
    });

    this.queryStringFilter.initialValue.subscribe((query) => {
      this.filterForm.patchValue(this.queryFormConverter.toForm(query));
      if (query.search) {
        const [searchKey, textSearch] = query.search.split(':');
        this.searchValue = { key: searchKey, value: textSearch };
      }
    });
  }

  private getRequests() {
    this.queryStringFilter.valueChanges.pipe(debounceTime(400), untilDestroyed(this)).subscribe((query) => {
      this.query = query;
      this.payoutService.getReadyRequests(query).subscribe((pagination) => (this.requestPagination = pagination));
    });
  }

  private getRequestsSummary() {
    this.payoutService.getReadyRequestsSummary().subscribe((data) => {
      this.requestsSummary = data;
      this.summaryItems = [
        {
          title: 'Total payments',
          value: this.currencyPipe.transform(data.total_amount, data.currency_symbol || '') || '',
        },
        {
          id: 'paypal',
          title: 'Amount for paypal ',
          value: this.currencyPipe.transform(data.paypal_amount, data.currency_symbol || '') || '',
        },
        {
          id: 'payoneer',
          title: 'Amount for payoneer',
          value: this.currencyPipe.transform(data.payoneer_amount, data.currency_symbol || '') || '',
        },
        {
          id: 'bank_account',
          title: 'Amount for bank',
          value: this.currencyPipe.transform(data.bank_amount, data.currency_symbol || '') || '',
        },
      ];
    });
  }
}
